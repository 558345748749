.roadmap_container {
    overflow: hidden;
    background-color: #70bc3d;
    /* margin-bottom: -5px; */
    position: relative;
    text-align: center;
  }

  img.roadmap_bg {
    width: 100%;
    height: 100%;
    margin-bottom: -5px;
  }

.outer_outerbox {
    display: flex;
    flex: 1 1 0%;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px;
    /* background: rgb(247, 77, 77); */
    background: transparent;
    color: black;
    position: absolute;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.outer_box {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-bottom: 0px;
    max-width: 2000px;
}

.roadmap_title {
    font-family: "Poppins", sans-serif;
    font-size: 2em;
    font-weight: bold;
    color: black;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.boxes {
    padding: 0px;
}

.writing {
    font-family: Sora;
    font-size: 1.2em;
    line-height: 1.5;
}

 /* .boxes li::after {
    content: "";
    background: white;
    position: absolute;
    height: 35px;
    width: 5px;
    left: 50%;
    bottom: -35px;
} */

.connector{
    content: "";
    /* background: rgb(255, 180, 180); */
    background: grey;
    position: absolute;
    height: 35px;
    width: 5px;
    left: 50%;
    bottom: -35px;
}


.boxes li {
    position: relative;
    background: white;
    border-radius: 10px;
    text-align: center;
    padding: 10px 15px;
    margin-bottom: 35px;
    list-style-type: none;
    /* box-shadow: rgb(255, 180, 180) 10px 10px 0px; */
    box-shadow: grey 10px 10px 0px;
}

/* .roadmap {
    padding: 4rem;
    background: #fff;
  } */

  .roadmap {
    margin: 48px;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    background: #fff;
}

.RoadmapLine {
    display: flex;
    width: 120px;
    justify-content: center;
}

.RoadmapLineDot {
    position: absolute;
    margin-left: -18px;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    border: 8px solid #0523c9;
    background-color: #fff;
}

.RoadmapCard-title {
    font-size: 37px;
    line-height: 44px;
}

.RoadmapCard-date {
    font-size: 24px;
    line-height: 29px;
}

.RoadmapCard-content {
    font-family: Balsamiq Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #000;
}

  .RoadmapLineCenter {
    width: 8px;
    background-color: #0523c9;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: visible;
}

.RoadmapLineDot {
    position: absolute;
    margin-left: -18px;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    border: 8px solid #0523c9;
    background-color: #fff;
}

.RoadmapEmpty {
    flex: 1 1;
    padding: 32px;
    border: 8px solid transparent;
}

.RoadmapRow {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.RoadmapRowFlip {
    flex-direction: row-reverse;
}

.RoadmapCard-Root {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #fff;
    border: 8px solid #0523c9;
    box-sizing: border-box;
    border-radius: 32px;
}
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: aqua;
    /* background-color: #d4f41f; */
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }


/* Bottom middle text */
.roadmap_bottom_middle {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}

  /* @media screen and (max-width: 1300px) {
  /* If on mobile, still in development
  .roadmap_container {
    display: none;
    visibility: hidden;
  }
} */
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }