video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.white {
  color: white;
}

.mint_button {
  background-color: #0523c9;
  border: none;
  border-radius: 40px;
  color: white;
  /* padding: 15px 32px; */
  padding: 1vw 2vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 16px; */
  font-size: 1vw;
  margin: 4px 2px;
  cursor: pointer;
}

.presale_title {
  color: yellow;
}

.mint_button_disabled {
  background-color: #0522c981;
  border: none;
  border-radius: 40px;
  color: rgba(255, 255, 255, 0.527);
  /* padding: 15px 32px; */
  padding: 1vw 2vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 16px; */
  font-size: 1vw;
  margin: 2vw 2vw;
  cursor: pointer;
  pointer-events: none;
}

.hero-container {
  /* background: url('images/img-home.jpg') center center/cover
  no-repeat; */
  /* background-image: url('../../public/images/img-home.jpg'); */
  /* background: rgb(3, 66, 0); */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
}

.hero-wrap {
  display: inline-block;
  position: relative;
}

.hero-wrap img + .hero-container {
  color: red;
  position: absolute;
  top: 0;
  left: 0;
}

/* Container holding the image and the text */
.container {
  overflow: hidden;
  /* background-color: rgb(247, 77, 77); */
  background-color: #70bc3d;
  position: relative;
  text-align: center;
  margin-bottom: -5px;
}

.presale_container {
  overflow: hidden;
  /* background-color: rgb(247, 77, 77); */
  background-color: black;
  position: relative;
  text-align: center;
  margin-bottom: -5px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Centered text */
.below_center {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 0px;
  left: 16px;
}

.countdown {
  font-size: 5vw;
  font-family: 'Orbitron', sans-serif;
}

@keyframes slide {
  0% {
    transform: translate3d(5vw, 0, 0);
  }
  50% {
    transform: translate3d(-70vw, 0, 0); /* The image width */
  }
  100% {
    transform: translate3d(5vw, 0, 0);
  }
}

.scrolling_image {
  animation: slide 50s linear infinite;
}

.meet {
  font-size: 5.3vw;
  color: khaki;
}

.howling {
  font-size: 2vw;
  color: khaki;
}

img.bg {
  width: 100%;
  height: 100%;
}

/* .moving-nfts {
  height: 200px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: repeat;
  background-size: cover;
  background-image: url(/static/media/temp-piggy-lots-of-piggy.c4478c7b.png);
  animation: backgroundScroll 10s linear 1;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  z-index: 0;
} */

.hero-container > h1 {
  color: black;
  font-size: 80px;
  /* margin-top: -100px; */
  font-family: 'Poppins', sans-serif;
}

.hero-container > p {
  margin-top: 8px;
  color: black;
  font-size: 32px;
  /* font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif; */
  font-family: "Poppins", sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 799px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }

/* If on mobile, remove top right text and replace with navbar */
/* .top-right {
  display: none;
  visibility: hidden;
} */

/* .container {
  display: none;
  visibility: hidden;
} */

}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}