/* .footer-container {
  /* background-color: #242424;
  background-color: white;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.footer_container {
  overflow: hidden;
  background-color: black;
  position: relative;
  text-align: center;
}

img.footer_bg {
  width: 100%;
  height: 100%;
}

.center_right {
  position: absolute;
  bottom: 0px;
  right: 2vw;
  top: 30%;
  /* transform: translate(0, -50%); */
  background-color: transparent;
  font-size: 1.5vw;
}

/* Bottom left text */
.bottom-development {
  position: fixed;
  bottom: 8px;
  left: 16px;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  /* padding: 24px; */
  color: #fff;
}

.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 8px;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 32px;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  padding: 0px;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  /* margin: 40px auto 0 auto; */
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 240px; */
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-self: center;
  margin-bottom: 16px;
}

.discfoot {
  color: #7289d9;
  padding-right: 1.5vw;
}

.twitfoot {
color: #1DA1F2;
padding-right: 1vw;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

.solana {
  color: black;
}

.solana-image {
  vertical-align: -14px;
  width: auto;
  height: 50px;
}

@media screen and (max-width: 700px) {

  /* .center_right {
    position: absolute;
    right: 2vw;
    top: 0px;
    transform: translate(0, 0);
    background-color: transparent;
    font-size: 1vw;
  } */

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

  /* @media screen and (min-width: 1301px) {
    /* If on mobile, still in development
    .in_development {
      display: none;
      visibility: hidden;
    }
  } */