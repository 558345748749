.FAQ_outer_outerbox {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0%;
    color: black;
}

.FAQ_outerbox {
    display: flex;
    flex: 1 1 0%;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 25px;
    color: black;
    /* background: rgb(77, 185, 247); */
    background: yellow;
}

.FAQ_box {
    display: flex;
    text-align: center;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    /* background: white; */
    /* box-shadow: rgb(77 185 247) 10px 10px 0px; */
}

.question_sections {
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-bottom: 0px;
    max-width: 550px;
}

.faq_title {
    font-family: 'Poppins', sans-serif;
    font-size: 2em;
    font-weight: bold;
    letter-spacing: 3px;
}

.FAQ_container {
    overflow: hidden;
    background-color: black;
    position: relative;
    text-align: center;
  }

  img.FAQ_bg {
    width: 100%;
    height: 100%;
  }

  .FAQ_centered {
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* Bottom middle text */
.FAQ_bottom_middle {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.questions {
    font-family: Sora;
    font-size: 1.2em;
    line-height: 1.5;
    padding: 9px;
}

/* @media screen and (max-width: 1300px) {
    /* If on mobile, still in development
    .FAQ_container {
      display: none;
      visibility: hidden;
    }
  } */